<template>
  <div>
    <v-card width="100%" min-height="100vh" tile color="#4C4C4C">
      <v-img src="@/assets/fold1.png" width="100%" min-height="100vh">
        <v-container class="header-class">
          <v-btn
            color="primary"
            class="py-6"
            dark
            rounded
            v-if="this.$vuetify.breakpoint.mdAndDown"
            @click="model = true"
          >
            <img src="@/assets/emoji.png" height="24px" />

            Menu</v-btn
          >
          <v-row
            class="mt-10"
            align="center"
            justify="center"
            v-if="this.$vuetify.breakpoint.lgAndUp"
          >
            <v-col cols="3">
              <img src="@/assets/comentaai.png" height="32px" />
            </v-col>
            <v-col cols="5">
              <v-btn text color="white" @click="scrollTo('fold2')"
                >Como Funciona?</v-btn
              >
              <v-btn text color="white" @click="scrollTo('fold3')"
                ><b>Planos</b></v-btn
              >
              <v-btn text color="white" @click="scrollTo('fold4')"
                ><b>Newsletter</b></v-btn
              >
            </v-col>
            <v-col class="text-right">
              <v-btn
                color="white"
                class="py-8 px-10 botao-cadastre rounded-pill"
                rounded
                x-large
                @click="dialog = true"
              >
                Cadastre-se Agora
                <v-icon color="primary">mdi-arrow-right </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-15" id="fold2">
            <v-col md="7" cols="10">
              <h1 class="h1-">Quanto vale a opinião de seus clientes?</h1>
              <div class="half my-10">
                <p>
                  Com o <b>Comenta Aí</b> você recebe a avaliação e comentários
                  dos seus clientes por meio de uma plataforma simples e ágil.
                </p>
              </div>
              <v-btn
                color="primary"
                class="py-8 px-10 botao-saiba-mais rounded-pill"
                dark
                rounded
                x-large
                v-if="this.$vuetify.breakpoint.lgAndUp"
                @click="scrollTo('fold2')"
              >
                Saiba Mais
              </v-btn>
              <v-btn
                color="primary"
                class="py-6 px-8"
                dark
                rounded
                small
                v-if="this.$vuetify.breakpoint.mdAndDown"
                >Saiba Mais</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </v-card>
    <v-navigation-drawer
      app
      v-model="model"
      color="primary"
      dark
      class="menu-mobile"
    >
      <v-container>
        <div class="text-center pt-5 pb-8">
          <img
            src="@/assets/comentaai.png"
            height="32px"
            style="max-width: 90%"
          />
        </div>
      </v-container>
      <v-list>
        <v-list-item
          class="border-menu-mobile py-3"
          style="border-top: 1px solid white"
        >
          <a @click="scrollMobileTo('fold2')">Como Funciona?</a>
        </v-list-item>
        <v-list-item class="border-menu-mobile py-3">
          <a @click="scrollMobileTo('fold3')">Planos</a>
        </v-list-item>
        <v-list-item class="border-menu-mobile py-3">
          <a @click="scrollMobileTo('fold4')"><b>Newsletter</b></a>
        </v-list-item>
        <v-list-item class="border-menu-mobile py-3">
          <a @click="dialog = true"><b>Cadastre-se aqui</b></a>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="dialog" :fullscreen="this.$vuetify.breakpoint.mdAndDown">
      <template>
        <v-card dark color="primary">
          <v-container fluid>
            <div class="text-right">
              <v-icon btn @click="dialog = false" color="white"
                >mdi-close</v-icon
              >
            </div>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col class="text-center">
                  <h1>Cadastre seu estabelecimento!</h1>
                  <h3>
                    Participe desta comunidade que está crescendo diariamente.
                  </h3>
                </v-col>
              </v-row>
              <v-alert v-if="success" type="success" class="my-5">
                Cadastrado realizado com Sucesso!
              </v-alert>
              <v-alert v-if="error" type="error" class="my-5">
                Empresa já cadastrada.
              </v-alert>
              <v-row>
                <v-col cols="12" md="2">
                  <b>CNPJ*</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    required
                    :rules="required"
                    v-model="object.cnpj"
                    v-mask="'##.###.###/####-##'"
                    type="tel"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <b>Razão Social*</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    required
                    :rules="required"
                    v-model="object.nome"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <b>Nome Fantasia*</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    required
                    :rules="required"
                    v-model="object.nome_fantasia"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <b>CEP*</b>
                  <v-text-field
                    v-model="object.cep"
                    filled
                    outlined
                    dense
                    hide-details
                    required
                    :rules="required"
                    @keyup="getViaCep()"
                    v-mask="'#####-###'"
                    type="tel"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <b>Logradouro*</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    required
                    :rules="required"
                    v-model="object.logradouro"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <b>Número*</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    required
                    :rules="required"
                    v-model="object.numero"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <b>Complemento</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    v-model="object.complemento"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <b>Bairro*</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    required
                    :rules="required"
                    v-model="object.bairro"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <b>Cidade*</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    required
                    :rules="required"
                    v-model="object.cidade"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <b>Estado*</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    required
                    :rules="required"
                    v-model="object.estado"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <b>Segmento*</b>
                  <v-select
                    :items="segmento"
                    filled
                    outlined
                    dense
                    hide-details
                    required
                    :rules="required"
                    v-model="object.store_segment"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <b>Facebook ( @SeuPerfil )</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    v-model="object.facebook"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <b>Instagram (@SeuPerfil )</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    v-model="object.instagram"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <b>Telefone</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    v-mask="'(##)# ####-####'"
                    v-model="object.phone"
                    type="tel"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <b>Informações do Estabelecimento</b>
                  <v-textarea
                    filled
                    outlined
                    dense
                    hide-details
                    v-model="object.store_details"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <b>Nome de Contato*</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    required
                    :rules="required"
                    v-model="object.contact_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <b>Número de Contato*</b>
                  <v-text-field
                    filled
                    type="tel"
                    outlined
                    dense
                    hide-details
                    required
                    :rules="required"
                    v-model="object.contact_number"
                    v-mask="'(##)# ####-####'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <b>E-mail de contato</b>
                  <v-text-field
                    filled
                    outlined
                    dense
                    hide-details
                    v-model="object.contact_email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <center>
                <v-btn
                  class="mt-5"
                  large
                  depressed
                  color="white"
                  light
                  @click="send()"
                  >Enviar</v-btn
                >
              </center>
            </v-form>
          </v-container>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  data: () => ({
    model: false,
    dialog: false,
    valid: false,
    success: false,
    error: false,
    object: {},
    segmento: [
      { value: 1, text: "Entretenimento" },
      { value: 2, text: "Gastronomia" },
      { value: 3, text: "Bebidas" },
      { value: 4, text: "Serviços" },
      { value: 5, text: "Lojas" },
    ],
    required: [(v) => !!v || "Required"],
  }),
  methods: {
    scrollTo(refName) {
      this.$emit("input", refName);
    },
    scrollMobileTo(refName) {
      this.model = false;
      this.$emit("input", refName);
    },
    send() {
      this.$refs.form.validate();
      if (this.valid === true) {
        this.success = false;
        this.error = false;
        const url = "https://e.beniclub.com/comentai/cadastroEmpresa/";
        var qs = require("qs");
        axios
          .post(url, qs.stringify(this.object))
          .then((res) => {
            if (res.data.status === "error") {
              this.error = true;
            }
            if (res.data.status === "success") {
              this.object = {};
              this.success = true;
            }
          })
          .catch(() => {
            this.error = true;
          })
          .finally(() => {
            setTimeout(() => {
              this.state = 3;
            }, 300);
          });
      }
    },
    getViaCep() {
      if (this.object.cep.length < 9) {
        this.dialog = false;
        this.dialog = true;
        this.object.logradouro = "";
        this.object.bairro = "";
        this.object.cidade = "";
        this.object.estado = "";
        return false;
      }
      const cep = this.object.cep.replace("-", "");
      const url = `https://viacep.com.br/ws/${cep}/json/`;
      axios
        .get(url)
        .then((res) => {
          this.dialog = false;
          this.dialog = true;
          this.object.logradouro = res.data.logradouro;
          this.object.bairro = res.data.bairro;
          this.object.cidade = res.data.localidade;
          this.object.estado = res.data.uf;
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    loadSegmento() {
      const url = `https://www.comenta.ai/processor/segmentos`;
      axios
        .get(url)
        .then((res) => {
          this.segmento = [];
          res.data.forEach((el) => {
            this.segmento.push({ value: el.id, text: el.title });
          });
        })
        .catch((err) => {
          console.error(err.message);
        });
    },
  },
  mounted() {
    this.loadSegmento();
  },
};
</script>

<style>
.header-class h1 {
  font-size: 80px !important;
  line-height: 120%;
  font-weight: bolder;
  color: #fff !important;
}

.header-class p {
  color: #fff !important;
  line-height: 28.8px;
  font-size: 18px;
  font-weight: 400;
}

.half {
  max-width: 380px !important;
}

.botao-saiba-mais {
  font-size: 22px !important;
  font-weight: 700 !important;
}

.botao-cadastre {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.menu-mobile a {
  color: #fff !important ;
}

.border-menu-mobile {
  border-bottom: thin solid white;
  font-size: 18px;
}

@media (max-width: 600px) {
  .header-class {
    padding: 30px !important;
  }

  .header-class h1 {
    font-size: 32px !important;
    margin-bottom: 20px;
  }

  .header-class .half {
    max-width: 100%;
  }
}
</style>
