<template>
  <div>
    <div ref="fold1"><fold1 v-model="ref" :text="text" /></div>
    <div ref="fold2"><fold2 v-model="ref" :text="text" /></div>
    <!-- <div ref="fold3"><fold3 v-model="ref" :text="text" /></div> -->
    <div ref="fold4"><fold4 v-model="ref" :text="text" /></div>
    <div ref="fold5"><fold5 v-model="ref" :text="text" /></div>
  </div>
</template>

<script>
import fold1 from '@/components/fold1'
import fold2 from '@/components/fold2'
// import fold3 from '@/components/fold3'
import fold4 from '@/components/fold4'
import fold5 from '@/components/fold5'
import axios from 'axios'
// import textJson from '@/assets/text-site.json'

export default {
  data: () => ({
    ref: '',
    text: {}
  }),
  computed: {
    // text() {
    //   return textJson
    // },
  },
  components: {
    fold1,
    fold2,
    // fold3, // REMOVIDOS VALORES DO SITE
    fold4,
    fold5,
  },
  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName]
      var top = element.offsetTop
      window.scrollTo({
        top: top,
        behavior: 'smooth',
      })
    },
    onLoad() {
      axios
        .get('https://www.comenta.ai/assets/text-site.json')
        .then((res) => {
          this.text = res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  watch: {
    ref() {
      this.scrollMeTo(this.ref)
    },
  },
  mounted() {
    this.onLoad()
  },
}
</script>

<style>
.v-btn {
  text-transform: none !important;
}

@media (min-width: 1903px) {
  .container {
    max-width: 1185px;
  }
}
</style>
