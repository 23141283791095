<template>
  <v-card width="100%" tile flat class="fold4" color="primary">
    <v-img src="@/assets/fold4.png" class="fold4-img" width="100%" min-height="100vh">
      <v-container class="py-15">
        <div class="text-left text-md-center mb-15">
          <img src="@/assets/emoji.png" width="132px" />
          <h1>Quer saber mais e aprimorar o seu negócio com base no que os seus clientes estão falando?</h1>
          <h2>
            Cadastre seu e-mail que em breve <br />entraremos em contato :) <br />
            <b> A opinião dos seus clientes vale muito! </b>
          </h2>
        </div>
        <div class="fold4-form text-center" v-if="!send">
          <v-text-field v-model="object.name" :error="error" filled rounded  placeholder="Nome" hide-details background-color="white" class="my-5"></v-text-field>
          <v-text-field v-model="object.email" :error="error" :rules="emailRules" filled rounded  hide-details placeholder="Email"  type="email" background-color="white" class="my-5"></v-text-field>
          <v-btn color="#4f4f4f" dark class="mt-2" rounded x-large depressed @click="sendEmail()">
            Cadastre-se Já
          </v-btn>
        </div>
        <div class="fold4-form text-center" v-if="send">
          <h2> {{text.sucessEmail }}</h2>
        </div>
      </v-container>
    </v-img>
  </v-card>
</template>

<script>
import axios from 'axios'


export default {
  props:{
    text: Object
  },
  data: () => ({
    object: {},
    send: false,
    error: false,
    emailRules: [(v) => !!v || 'E-mail é necessário', (v) => /.+@.+\..+/.test(v) || 'E-mail precisa ser válido'],
  }),
  methods: {
    sendEmail() {
      if(!this.object.name || !this.object.email){
        this.error = true
      }
      var qs = require('qs');
      const url = 'https://www.comenta.ai/cadastrar'
      axios
        .post(url, qs.stringify(this.object))
        .then(() => {
          this.send = true
        })
        .catch((err) => console.log(err))
    },
  },
}
</script>

<style>
.fold4 h1 {
  margin: 40px auto;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 70px;
  max-width: 800px;
  color: #fff !important;
}

.fold4 h2 {
  max-width: 800px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 40px !important;
  margin: 40px auto;
  color: #fff !important;
}

.fold4 h3 {
  font-size: 24px !important;
  margin-bottom: 60px !important;
  margin-left: 10px;
  color: #fff !important;
}

.fold4-card {
  position: relative;
}

.fold4-card h1 {
  font-size: 64px !important;
  font-weight: bold;
  color: #ff3366 !important;
}

.fold4-card h2 {
  font-size: 28px !important;
  max-width: 100%;
  color: #1f1f1f !important;
  font-weight: bold;
}

.fold4-card h1 span {
  font-size: 24px !important;
}

.fold4-card p {
  color: #1f1f1f !important;
}

.header-class .half {
  max-width: 60%;
}

.fold4-form {
  max-width: 452px;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .fold4 h1 {
    font-size: 28px;
    line-height: 42px;
    max-width: 80%;
    margin-left: 0px !important ;
  }

  .fold4-img {
    padding: 20px !important;
  }

  .fold4 h2 {
    max-width: 100%;
    font-size: 14px !important;
    line-height: 23px !important;
  }

  .fold4 h3 {
    font-size: 12px !important;
    margin-bottom: 30px !important;
    margin-left: 11px;
  }
  .fold4-form {
    max-width: 100%;
    margin: 0 auto;
  }
}
</style>
