import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FF3366',
        secondary: '#4C4C4C',
        accent: '#E5E5E5',
      },
    },
  },
})
