<template>
  <v-card width="100%" tile flat class="fold5" color="#4f4f4f">
    <v-container class="py-10">
      <div class="text-left mb-15">
        <h1>{{ text.titleFooter }}</h1>
        <p>
          {{ text.subtitleFooter }}
        </p>
      </div>

      <v-row class="mb-15 fold5-sub">
        <v-col cols="12" md="5">
          contato@comenta.ai <br />
          <v-icon color="white" @click="goTo('https://wa.me/' + ph1)">mdi-whatsapp</v-icon> {{ text.phone }} <br />
          <v-icon color="white" @click="goTo('https://wa.me/' + ph2)">mdi-whatsapp</v-icon> {{ text.phone_two }}
        </v-col>
        <v-col cols="6" md="2">
          <b>Sobre</b> <br />
          <br />
          <a @click="scrollTo('fold2')">Como Funciona?</a> <br />
          <a text color="white" @click="scrollTo('fold3')">Planos</a> <br />
          <a text color="white" @click="scrollTo('fold4')">Cadastre-se</a> <br />
        </v-col>
        <v-col cols="6" md="2"> </v-col>
        <v-col cols="12" md="3">
          Social Media <br />
          <br />
          <v-btn fab small depressed class="mr-2" color="primary" @click="goTo(text.facebook)" v-if="text.facebook"><v-icon dark>mdi-facebook</v-icon></v-btn>
          <v-btn fab small depressed class="mr-2" color="primary" @click="goTo(text.twitter)" v-if="text.twitter"><v-icon dark>mdi-twitter</v-icon></v-btn>
          <v-btn fab small depressed class="mr-2" color="primary" @click="goTo(text.instagram)" v-if="text.instagram"><v-icon dark>mdi-instagram</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    text: Object,
  },
  data: () => ({
    model: false,
  }),
  methods: {
    scrollTo(refName) {
      this.$emit('input', refName)
    },
    goTo(item) {
      window.location = item
    },
  },
  computed: {
    ph1() {
      return this.text.phone.replace(/[^0-9.]/g, '')
    },
    ph2() {
      return this.text.phone_two.replace(/[^0-9.]/g, '')
    },
  },
}
</script>

<style>
.fold5 {
  padding-top: 80px;
  z-index: 10 !important;
  color: #fff !important;
}

.fold5 a {
  color: white !important;
  text-decoration: none !important;
}

.fold5 a:hover {
  text-decoration: underline;
}

.fold5-sub {
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 29px !important;
  letter-spacing: 0em !important;
}

.fold5 h1 {
  font-size: 48px !important;
  font-weight: 700;
  color: #fff !important;
}

.fold5 h2 {
  font-size: 24px !important;
  font-weight: 400;
  margin: 20px auto;
  color: #fff !important;
}

.fold5 h3 {
  font-size: 24px !important;
  margin-bottom: 60px !important;
  margin-left: 10px;
  color: #fff !important;
}

.fold5-card {
  position: relative !important;
  border-radius: 40px !important;
}

.fold5-star {
  position: absolute !important;
  top: -32px !important;
  left: calc(50% - 35px);
  z-index: 100 !important;
}

.fold5-card h1 {
  font-size: 64px !important;
  font-weight: bold;
  color: #ff3366 !important;
}

.fold5-card h2 {
  font-size: 28px !important;
  max-width: 100%;
  color: #4f4f4f !important;
  font-weight: bold;
}

.fold5-card h1 span {
  font-size: 24px !important;
}

.fold5-card p {
  color: #1f1f1f !important;
}

.header-class .half {
  max-width: 60%;
}

.botao-card {
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 160% !important;
}

@media (max-width: 600px) {
  .fold5 h1 {
    font-size: 32px !important;
  }

  .fold5 {
    padding: 20px !important;
  }

  .fold5 h2 {
    max-width: 100%;
    font-size: 18px !important;
  }

  .fold5 h3 {
    font-size: 12px !important;
    margin-bottom: 30px !important;
    margin-left: 11px;
  }
}
</style>
